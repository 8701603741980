<template>
  <transition name="fade">
    <div class="modal-wrap" v-if="modalIsVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Вы уверены что хотите удалить объект из списка <span v-if="scope">"{{ scope }}"</span> ?</h5>
            <button type="button"
                    class="close"
                    aria-label="Close"
                    v-on:click.prevent="confirm(false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary mr-2" v-on:click="confirm(true)">Да</button>
            <button type="button" class="btn btn-outline-primary" v-on:click="confirm(false)">Отмена</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/patterns/event-bus.js';

export default {
  data() {
    return {
      modalIsVisible: false,
      scope: null
    };
  },
  methods: {
    closeModal() {
      this.modalIsVisible = false;
    },
    openModal(scope) {
      this.scope = scope;
      this.modalIsVisible = true;
    },
    confirm(val) {
      this.closeModal();
      EventBus.$emit('changeConfirmState', val);
    }
  },
  mounted() {
    EventBus.$on('openModal', this.openModal);

    document.body.addEventListener('keyup', e => {
      if (this.modalIsVisible) {
        if (e.code == 'Escape') {
          this.confirm(false)
        } else if (e.code == 'Enter') {
          this.confirm(true)
        }
      }
    })
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  justify-content: center;
}
</style>
