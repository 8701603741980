import errorHandler from '@/lib/ErrorHandler';
import { apolloClient } from './apollo';
import * as internalAdmin from '@/mutations/internalAdmin';
import user from '@/lib/data_mappers/user.js';
import store from '@/store';

const showError = (error) => {
  const err = error || 'Произошла ошибка. Не удалось найти пользователя';
  alert(err);
  errorHandler.handleError(err);
}

async function getUserInfo(args) {
  if (args.query == null) return null;

  const x = await user.getListUsers({
    first: 5,
    search: {
      query: args.query,
      inFields: []
    },
    hasQueries: false
  }).then((res) => {
    if (!res || res.length == 0) showError();

    return res;
  })
  .catch((error) => {
    showError(error);
  });

  return x;
}

async function assignObjectsToUsers(fields) {

  const x = getUserInfo(fields[0]).then((res) => {
    if (res && res.length == 0) return;

    let userId = !res ? null : res[0].id;

    const usersToObjects = [{
      investmentObjectId: +fields[0].investmentObjectId,
      userId
    }];

    const x2 = apolloClient.mutate({
      mutation: internalAdmin.INVESTMENT_OBJECT_ASSIGN_TO_USERS,
      variables: {
        usersToObjects
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.investmentObjectAssignToUsers.status;
      }
    })
    .catch((error) => {
      errorHandler.handleError(error, usersToObjects);
    });

    return x2;
  });
  
  return x;
}

export default assignObjectsToUsers;
