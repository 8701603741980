const BASECOLUMNWIDTH = 150;

import Handsontable from 'handsontable';
import { apolloClient } from './../lib/apollo';
import constants from '@/assets/data/grid_constants';
import * as internalAdminMutations from '@/mutations/internalAdmin';
import { EventBus } from '@/patterns/event-bus.js';
import store from '../store';
import moment from 'moment';
import { changeScopeMutation } from '@/lib/changeScope';

const momentFormat = 'DD.MM.YYYY HH:mm';

export const startCalculation = async (id, startNow) => {
  const x = await apolloClient.mutate({
    mutation: internalAdminMutations.START_CALCULATION_MUTATION,
    variables: {
      investmentObjectId: id,
      startNow
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.investmentObjectStartCalculation.status;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

class BaseDataTable {
  #data = null;
  constructor(sourceArr, data) {
    this.sourceArr = sourceArr;
    this.#data = data;
  }

  applyObjects(data) {
    this.#data = data;
  }

  removeLastComma(str){        
    // var n = str.lastIndexOf(",");
    // var a = str.substring(0, n);
    // return a;
    return str;
  }

  generateControlPanel = async (instance, td, row, col, prop, value, cellProperties) => {
    const objId = instance.getCell(row, 1).textContent;
    const currentScope = store.getters.getScope;
    let workflowScopes;
    let address;
    let jk;
    const table = this;

    if (this.#data && this.#data.filter(el => el.id == objId).length != 0) {
      workflowScopes = this.#data.filter(el => el.id == objId)[0].workflowScopes;
      const jkIsExist = this.#data.filter(el => el.id == objId)[0].jkInfo;
      const filterAddress = this.removeLastComma(this.#data.filter(el => el.id == objId)[0].address).split(',').filter(x => x.indexOf('МКАД') == -1);
      address = filterAddress.filter((x, i) => i >= filterAddress.length - 2 );
      jk = jkIsExist ? this.#data.filter(el => el.id == objId)[0].jkInfo.displayName : null;
    }

    let wrapper = document.createElement('div');
    wrapper.className = 'p-1 pb-2';

    let btnGroup = document.createElement('div');
    btnGroup.className = 'btn-group custom pt-1';

    const btnList = [
      {
        scope: 'pending',
        title: 'В работе'
      },
      {
        scope: 'visits',
        title: 'Просмотры'
      },
      {
        scope: 'favorite',
        title: 'Оценка'
      },
      {
        scope: 'legalChecks',
        title: 'Юридические проверки',
        hiddenForRoles: ['user']
      },
      {
        scope: 'portfolio',
        title: 'Одобрено на сделку',
        hiddenForRoles: ['user']
      },
      {
        scope: 'algorithmMistake',
        title: 'Ошибки'
      }
    ];

    const scopeButtonsInner = [...btnList].filter(el => {
      if (el.hiddenForRoles) {
        return el.hiddenForRoles.filter(el => store.getters.getCurrentRole.includes(el)).length != 0 || store.getters.getCurrentRole.length == 0 ? false : el;
      }
      return el;
    });

    let linkOpenAnalysis = document.createElement('a');
    linkOpenAnalysis.className = 'link cursor-pointer link-open-analysis';
    linkOpenAnalysis.href = `${window.location.origin}/#/object-form/${objId}`;
    linkOpenAnalysis.setAttribute('target', '_blank');

    let addressStreet = document.createElement('span');
    addressStreet.className = 'street';
    addressStreet.innerHTML = address && address.length != 0 ? address[0] : '';

    let addressHouse = document.createElement('span');
    addressHouse.className = 'house';
    addressHouse.innerHTML = address && address.length != 0  ? `, ${address[1]}` : '';

    if (jk) {
      linkOpenAnalysis.innerHTML = `<span class="text-ellipsis">${jk}</span>`;
    } else {
      linkOpenAnalysis.appendChild(addressStreet);
      linkOpenAnalysis.appendChild(addressHouse);
    }

    scopeButtonsInner.forEach((el, i) => {
      let btn = document.createElement('button');
      btn.className = `btn btn-sm btn-primary btn-move-to-${el.scope}`;
      btn.title = el.title;

      if (workflowScopes) {
        btn.dataset.disabled = !workflowScopes.some(elInner => elInner == el.scope);
        scopeButtonsInner[i].disabled = !workflowScopes.some(elInner => elInner == el.scope);
      }

      Handsontable.dom.addEvent(btn, 'mousedown', function() {
        if (!el.disabled) {
          EventBus.$emit('openModal', el.title);
          EventBus.$once('changeConfirmState', state => {
            if (state == true) {
              if (el.scope == currentScope) {
                instance.alter('remove_row', row, 1);
              }

              table.changeScope({ el, remove: true, btn, id: objId, makeDisabled: true });
            }
    
            return false;
          });
        } else {
          table.changeScope({ el, remove: false, btn, id: objId, makeDisabled: false });
        }
        
      });

      btnGroup.appendChild(btn);
    });
    
    let btnRecalc = document.createElement('button');
    btnRecalc.className = 'btn-handsontable-recalc';
    btnRecalc.title = 'Пересчитать';

    Handsontable.dom.empty(td);
    btnGroup.appendChild(btnRecalc);
    wrapper.appendChild(linkOpenAnalysis);
    wrapper.appendChild(btnGroup);
    td.appendChild(wrapper);

    return td;
  }

  calcCountComment = async (instance, td, row, col, prop, value, cellProperties) => {
    const objId = instance.getCell(row, 1).textContent;

    if (this.#data && this.#data.filter(el => el.id == objId).length != 0) {
      const fields = ['comment', 'commentAdmin', 'commentMainManager', 'legalCheckReport', 'commentValuer'];
      const currentObject = this.#data.filter(el => el.id == objId)[0];

      let count = 0;

      fields.forEach(field => { if (currentObject[field] != null) count += 1 })

      td.innerHTML = count;
      td.className = 'htDimmed';
    }
  }

  changeScope(args) {
    const currentIndex = this.#data.findIndex(x => x.id == args.id);

    changeScopeMutation(args.id, args.el.scope, args.remove).then(res => {
      if (res.status) {
        args.el.disabled = args.makeDisabled;
        args.btn.dataset.disabled = args.makeDisabled;

        const payload = {
          i: currentIndex,
          investmentObject: res.investmentObject
        }

        store.commit('changeObject', payload);
      }
    });
  }

  updateData(list, obj, formatDate = false, exportObjectsToExcel = false) {
    if (list && list.length > 0) {
      const res = [];

      list.forEach((el) => {
        const listInner = [];
        const columns = constants[obj].filter(el => !el.hidden);

        listInner.push('controlPanel');

        columns.forEach(col => {
          if (col.new) {
            listInner.push('')
          }
          if (col.value == 'countComment') {
            listInner.push('countComment');
          }
          if (col.value == 'clientTags') {
            if (!el.client) return listInner.push('');
            const tags = el.client.hasOwnProperty('tags') ? el.client.tags : '';
            const tagsInline = Array.from(tags, x => x.name).join(', ');
            listInner.push(tagsInline);
            return;
          }
          if (col.value == 'commentFromCalcClaim') {
            if (!el.commentFromCalcClaim) return listInner.push('');
          }
          if (col.value == 'clientPhones') {
            if (!el.client) return listInner.push('');
            const phones = el.client.hasOwnProperty('phones') ? el.client.phones : '';
            const phonesInline = Array.from(phones, x => x.phone).join(', ');
            listInner.push(phonesInline);
            return;
          }
          if (col.value.includes('check__')) {
            const v = this.getCheckValue(col.value, el.checkValues);
            listInner.push(v);
          }
          if (col.customType == 'dateTimeCustom' && !col.parentFieldName && formatDate) {
            if (!el[col.value]) return listInner.push('');

            const utcTime = moment.utc(el[col.value]);
            const val = moment(utcTime).local().format(momentFormat);
            listInner.push(val);
            return;
          }
          if (col.value == 'calcClaimSource') {
            if (!el[col.value] || Object.keys(el[col.value]).length === 0) return listInner.push('');

            const str = el[col.value].split(',').length > 1 ? el[col.value].split(',').join(', ') : el[col.value];
            listInner.push(decodeURIComponent(str));
            return;
          }
          if (col.value == 'calcClaimUtmParams') {
            if (!el[col.value] || Object.keys(el[col.value]).length === 0) return listInner.push('');

            let str = '';
            for (const [key, value] of Object.entries(el[col.value])) {
              let v = value.length > 0 ? value.join(', ') : value;
              str += `${key}: ${decodeURIComponent(v)}\n`;
            }
            listInner.push(str);
            return;
          }
          if (col.value == 'address') {
            let o = exportObjectsToExcel ? el.address : {
              address: el.address,
              coordinate: el.coordinate
            };
            listInner.push(JSON.stringify(o));
            return;
          }
          for (const [key, val] of Object.entries(el)) {
            const parentKeys = col.hasOwnProperty('parentFieldName') ? col.parentFieldName.split('.') : null;
            if (key == col.value || (parentKeys  && key == parentKeys[0])) {
              if (col.hidden) {
                return;
              } else if (key == 'id') {
                listInner.push({ id: el.id, url: el.publicUrl });
              } else if (key == 'duration') {
                listInner.push(`${val.duration} ${constants.unitShort.filter(el => el.value == val.unit)[0].text}`);
              } else if (col.sourceObj) {
                if (col.parentFieldName) {
                  const k = col.value;
                  const v = col.value.replace(col.parentFieldName, '');
                  const resV = val[this.lowercaseFirstLetter(v)];
                  const customVal = this.getSelectedValue(this.sourceArr, k, resV);
                  listInner.push(customVal);
                  return;
                }
                const customVal = this.getSelectedValue(this.sourceArr, key, val);
                listInner.push(customVal);
              } else if (col.parentFieldName) {
                if (!val) {
                  listInner.push('');
                  return;
                }

                if (parentKeys.length > 1) {
                  let keys = [];

                  parentKeys.forEach((el, i) => {
                    const fieldName = i != 0 ? this.uppercaseFirstLetter(el) : el;
                    keys.push(fieldName);
                  });

                  const v = col.value.replace(keys.join(''), '');
                  if (!val[parentKeys[1]]) {
                    listInner.push('');
                    return;
                  }
                  listInner.push(val[parentKeys[1]][this.lowercaseFirstLetter(v)]);
                  return;
                }

                const v = col.value.replace(col.parentFieldName, '');

                if (col.customType == 'dateTimeCustom' && formatDate) {
                  const utcTime = moment.utc(val[this.lowercaseFirstLetter(v)]);
                  const value = moment(utcTime).local().format(momentFormat);
                  listInner.push(value);
                  return;
                }

                listInner.push(val[this.lowercaseFirstLetter(v)]);
              } else {
                listInner.push(val);
              }
            }
          }
        })
        res.push(listInner);
      });
      return res;
    }
  }

  lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getCheckValue(v, checkValues) {
    const checkObj = checkValues.filter(el => el.field.mutationName == v)[0];
    const values = Array.from(checkObj.field.values, x => x.name);
    const val = checkObj.value;
    const label = val && values.includes(val) ? checkObj.field.values.filter(el => el.name == val)[0].label : null;
    return label;
  }

  getSelectedValue(dataTable, selectedVal, initialFieldVal, key = 'value', value = 'text') {
    const momentFormat = 'DD.MM.YYYY';
    const targetField = dataTable.filter(el => el.value == selectedVal || el.filterName == selectedVal)[0];
    let hasOptions = targetField.hasOwnProperty('sourceObj');
    let isDate = targetField.hasOwnProperty('customType') && targetField.customType == 'dateTimeCustom';
    let targetObj = targetField.sourceObj;
    let valueIsExist = targetObj ? targetObj.some(el => el.value == initialFieldVal || el.text == initialFieldVal) : null;

    if (selectedVal == 'discountFromPriceMarket') {
      return (initialFieldVal / 100).toString();
    }

    let val;

    if (hasOptions && initialFieldVal != null && initialFieldVal != '' && valueIsExist) {
      val = targetObj.filter(el => el[key].toLowerCase() == initialFieldVal.toLowerCase())[0][value]
    } else if (isDate && initialFieldVal) {
      if (initialFieldVal.includes('T')) {
        val = initialFieldVal;
        return val;
      }

      if (moment(initialFieldVal, momentFormat).isValid()) {
        val = moment(initialFieldVal, momentFormat).format('YYYY-MM-DD');
      }
      const arr = initialFieldVal.split(/[.+-]/g);

      if (arr.length < 3) {
        val = initialFieldVal;
      }
    } else {
      val = initialFieldVal;
    }

    return val;
  }

  getNestedHeaders() {
    const nestedHeaders = [];
    const firstLine = [];
    const secondLine = [];
    let headerGroups = [];
    let collapsibleColumns = [];

    headerGroups = Array.from(this.sourceArr, x => x.columnGroup);

    var countGroupFields = headerGroups.reduce(function(acc, el) {
      acc[el] = (acc[el] || 0) + 1;
      return acc;
    }, {});

    headerGroups.forEach((el, i, arr) => {
      if (el == undefined) {
        firstLine.push('');
      }

      if (el) {
        if (el == arr[i - 1]) {
          return;
        } else {
          firstLine.push({
            label: el,
            startIndex: i
          })
        }
      }
    });

    firstLine.forEach(el => {
      if (el) {
        collapsibleColumns.push({
          row: '-2',
          col: el.startIndex,
          collapsible: true,
          label: el.label
        })
        return el.colspan = countGroupFields[el.label];
      }
    });

    this.sourceArr.forEach((el, i) => {
      secondLine.push(el.title);
    });

    nestedHeaders.push(firstLine);
    nestedHeaders.push(secondLine);

    return {nestedHeaders, collapsibleColumns};
  }

  updateColumns(changeFields, orderFields) {
    const columns = this.sourceArr;

    columns.unshift()

    const columnSorting = {
      indicator: false,
      headerAction: false,
      compareFunctionFactory() {
        return function comparator() {
          return 0;
        };
      }
    }

    columns.forEach((el) => {
      let val = el.orderName || el.value;
      if (el.value == 'controlPanel') {
        el.renderer = this.generateControlPanel;
      }
      if (el.value == 'countComment') {
        el.renderer = this.calcCountComment;
      }
      if (changeFields.indexOf(val) == -1) {
        if (!el.hasOwnProperty('readOnly')) {
          el.readOnly = true;
        }
      }
      if (orderFields.indexOf(val) == -1) {
        el.columnSorting = columnSorting;
      }
    });

    return columns;
  }

  updateColWidth() {
    const colWidths = [];

    this.sourceArr.forEach((el) => {
      const objWidth = el.width || BASECOLUMNWIDTH;

      colWidths.push(objWidth);
    });

    return colWidths;
  }

  getFilterFields(filterFields) {
    const columns = this.sourceArr;
    let arr = [];

    columns.forEach((el, index) => {
      let val = el.filterName || el.value;

      if (filterFields && filterFields.indexOf(val) != -1) {
        arr.push(index);
      }
    })
    
    return arr;
  }
}

export default BaseDataTable;
