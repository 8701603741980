import { apolloClient } from '@/lib/apollo';
import * as internalAdminMutations from '@/mutations/internalAdmin';

export const changeScopeMutation = async (id, scope, remove) => {
  const x = await apolloClient.mutate({
    mutation: internalAdminMutations.CHANGE_SCOPE_MUTATION,
    variables: {
      investmentObjectId: id,
      scope,
      remove
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.investmentObjectChangeScope;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export const scopeButtons = [
  {
    scope: 'pending',
    title: 'В работе',
    disabled: false
  },
  {
    scope: 'visits',
    title: 'Просмотры',
    disabled: false
  },
  {
    scope: 'favorite',
    title: 'Оценка',
    disabled: false
  },
  {
    scope: 'legalChecks',
    title: 'Юридические проверки',
    disabled: false
  },
  {
    scope: 'portfolio',
    title: 'Одобрено на сделку',
    disabled: false,
    hiddenForRoles: ['user']
  },
  {
    scope: 'algorithmMistake',
    title: 'Ошибки',
    disabled: false
  }
];
